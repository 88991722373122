const crc_table = new Array(
    0x00, 0x31, 0x62, 0x53, 0xc4, 0xf5, 0xa6, 0x97, 0xb9, 0x88, 0xdb, 0xea, 0x7d, 0x4c, 0x1f, 0x2e,
    0x43, 0x72, 0x21, 0x10, 0x87, 0xb6, 0xe5, 0xd4, 0xfa, 0xcb, 0x98, 0xa9, 0x3e, 0x0f, 0x5c, 0x6d,
    0x86, 0xb7, 0xe4, 0xd5, 0x42, 0x73, 0x20, 0x11, 0x3f, 0x0e, 0x5d, 0x6c, 0xfb, 0xca, 0x99, 0xa8,
    0xc5, 0xf4, 0xa7, 0x96, 0x01, 0x30, 0x63, 0x52, 0x7c, 0x4d, 0x1e, 0x2f, 0xb8, 0x89, 0xda, 0xeb,
    0x3d, 0x0c, 0x5f, 0x6e, 0xf9, 0xc8, 0x9b, 0xaa, 0x84, 0xb5, 0xe6, 0xd7, 0x40, 0x71, 0x22, 0x13,
    0x7e, 0x4f, 0x1c, 0x2d, 0xba, 0x8b, 0xd8, 0xe9, 0xc7, 0xf6, 0xa5, 0x94, 0x03, 0x32, 0x61, 0x50,
    0xbb, 0x8a, 0xd9, 0xe8, 0x7f, 0x4e, 0x1d, 0x2c, 0x02, 0x33, 0x60, 0x51, 0xc6, 0xf7, 0xa4, 0x95,
    0xf8, 0xc9, 0x9a, 0xab, 0x3c, 0x0d, 0x5e, 0x6f, 0x41, 0x70, 0x23, 0x12, 0x85, 0xb4, 0xe7, 0xd6,
    0x7a, 0x4b, 0x18, 0x29, 0xbe, 0x8f, 0xdc, 0xed, 0xc3, 0xf2, 0xa1, 0x90, 0x07, 0x36, 0x65, 0x54,
    0x39, 0x08, 0x5b, 0x6a, 0xfd, 0xcc, 0x9f, 0xae, 0x80, 0xb1, 0xe2, 0xd3, 0x44, 0x75, 0x26, 0x17,
    0xfc, 0xcd, 0x9e, 0xaf, 0x38, 0x09, 0x5a, 0x6b, 0x45, 0x74, 0x27, 0x16, 0x81, 0xb0, 0xe3, 0xd2,
    0xbf, 0x8e, 0xdd, 0xec, 0x7b, 0x4a, 0x19, 0x28, 0x06, 0x37, 0x64, 0x55, 0xc2, 0xf3, 0xa0, 0x91,
    0x47, 0x76, 0x25, 0x14, 0x83, 0xb2, 0xe1, 0xd0, 0xfe, 0xcf, 0x9c, 0xad, 0x3a, 0x0b, 0x58, 0x69,
    0x04, 0x35, 0x66, 0x57, 0xc0, 0xf1, 0xa2, 0x93, 0xbd, 0x8c, 0xdf, 0xee, 0x79, 0x48, 0x1b, 0x2a,
    0xc1, 0xf0, 0xa3, 0x92, 0x05, 0x34, 0x67, 0x56, 0x78, 0x49, 0x1a, 0x2b, 0xbc, 0x8d, 0xde, 0xef,
    0x82, 0xb3, 0xe0, 0xd1, 0x46, 0x77, 0x24, 0x15, 0x3b, 0x0a, 0x59, 0x68, 0xff, 0xce, 0x9d, 0xac
);

export function cal_crc_table(array) {
    var crc = 0;
    var i = 1;
    var len = array[2];
    while (len--) {
        crc = crc_table[crc ^ array[i]];
        i++;
    }
    return crc;
}

function g_convert_native_float_to_float16(value) {
    var buffer1 = new ArrayBuffer(4);
    var f32inf = new Uint32Array(buffer1);
    var f32inff = new Float32Array(buffer1);

    var buffer2 = new ArrayBuffer(4);
    var f16inf = new Uint32Array(buffer2);
    var f16inff = new Float32Array(buffer2);

    var buffer3 = new ArrayBuffer(4);
    var magic = new Uint32Array(buffer3);
    var magicf = new Float32Array(buffer3);

    var buffer4 = new ArrayBuffer(4);
    var _in = new Uint32Array(buffer4);
    var _inf = new Float32Array(buffer4);

    f32inf[0] = 255 << 23;
    f16inf[0] = 31 << 23;
    magic[0] = 15 << 23;
    const sign_mask = 0x80000000;
    const round_mask = ~0xFFF;

    _inf[0] = value;
    var sign = _in[0] & sign_mask;
    _in[0] ^= sign;

    var out = 0;

    if (_in[0] >= f32inf) {
        out = (_in[0] > f32inf) ? 0x7FFF : 0x7C00;
    } else {
        _in[0] &= round_mask;
        _inf[0] *= magicf[0];
        _in[0] -= round_mask;
        if (_in[0] > f16inf[0]) {
            _in[0] = f16inf[0];
        }
        out = _in[0] >> 13;
    }

    out |= (sign >> 16);

    return Number(out);
}
export const MMC_Gimbal_Z40N = {
        /**
     * 拍照
     * @param {*} param0 
     * @returns 
     */
         take_photo({ longitude = 0, latitude = 0, altitude = 0 }) {
            var buff = new Array(0xA5, 0x01, 16, /*index*/ 0x00, 0x00, /*altitude*/ 0x00, 0x00, 0x00, 0x00,
                /*latitude*/
                0x00, 0x00, 0x00, 0x00, /*longitude*/ 0x00, 0x00, 0x00, 0x00, 0x00);
    
            buff[5] = (altitude) & 0xff;
            buff[6] = (altitude >> 8) & 0xff;
            buff[7] = (altitude >> 16) & 0xff;
            buff[8] = (altitude >> 24) & 0xff;
    
            buff[9] = (latitude) & 0xff;
            buff[10] = (latitude >> 8) & 0xff;
            buff[11] = (latitude >> 16) & 0xff;
            buff[12] = (latitude >> 24) & 0xff;
    
            buff[13] = (longitude) & 0xff;
            buff[14] = (longitude >> 8) & 0xff;
            buff[15] = (longitude >> 16) & 0xff;
            buff[16] = (longitude >> 24) & 0xff;
    
            buff[17] = cal_crc_table(buff);
            return buff
        },
        /**
         * 
         * @param {*} status: 1 开始录像, 0 停止录像
         */
        record(status) {
            var buff = new Array(0xA5, 0x05, 0x03, 0x00, 0x00);
            if (status) {
                buff[3] = 2;
            } else {
                buff[3] = 0;
            }
            buff[4] = cal_crc_table(buff);
            return buff
        },
    zoom(focus_ctrl) {
        var buff = new Array(0xA5, 0x11, 0x04, 0x00, 0x00, 0x00);
        buff[3] = (focus_ctrl >>> 0) & 0xff;
        buff[5] = cal_crc_table(buff);

        return buff

    },
    change_pitch_angle(angle) {
        var buff = new Array(0xA5, 0x22, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    change_yaw_angle(angle) {
        var buff = new Array(0xA5, 0x23, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    gimbal_mode_ctrl(mode) {
        var buff = new Array(0xA5, 0x08, 0x03, 0x00, 0x00);
        buff[3] = mode;
        buff[4] = cal_crc_table(buff);
        return buff
    },

    //轮盘
    gimbal_pitch_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        console.log(buff);
        return buff
    },
    gimbal_yaw_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x07, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        console.log(buff);

        return buff
    }
}
export const MMC_Gimbal_Z40 = {
    gimbal_mode_ctrl(mode) {
        var buff = new Array(0xA5, 0x08, 0x03, 0x00, 0x00);
        buff[3] = mode;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    zoom(zoom_ctrl) {
        var buff = new Array(0xA5, 0x04, 0x03, 0x00, 0x00);
        buff[3] = (zoom_ctrl >>> 0) & 0xff;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    change_pitch_angle(angle) {
        var buff = new Array(0xA5, 0x22, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    change_yaw_angle(angle) {
        var buff = new Array(0xA5, 0x23, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    camera_mode_ctrl(camera_mode) {
        var buff = new Array(0xA5, 0x09, 0x03, 0x00, 0x00);
        buff[3] = camera_mode;
        buff[4] = cal_crc_table(buff);
        return buff;
    },
    /**
     * 拍照
     * @param {*} param0 
     * @returns 
     */
    take_photo({ longitude = 0, latitude = 0, altitude = 0 }) {
        var buff = new Array(0xA5, 0x01, 16, /*index*/ 0x00, 0x00, /*altitude*/ 0x00, 0x00, 0x00, 0x00,
            /*latitude*/
            0x00, 0x00, 0x00, 0x00, /*longitude*/ 0x00, 0x00, 0x00, 0x00, 0x00);

        buff[5] = (altitude) & 0xff;
        buff[6] = (altitude >> 8) & 0xff;
        buff[7] = (altitude >> 16) & 0xff;
        buff[8] = (altitude >> 24) & 0xff;

        buff[9] = (latitude) & 0xff;
        buff[10] = (latitude >> 8) & 0xff;
        buff[11] = (latitude >> 16) & 0xff;
        buff[12] = (latitude >> 24) & 0xff;

        buff[13] = (longitude) & 0xff;
        buff[14] = (longitude >> 8) & 0xff;
        buff[15] = (longitude >> 16) & 0xff;
        buff[16] = (longitude >> 24) & 0xff;

        buff[17] = cal_crc_table(buff);
        return buff
    },
    /**
     * 
     * @param {*} status: 1 开始录像, 0 停止录像
     */
    record(status) {
        var buff = new Array(0xA5, 0x05, 0x03, 0x00, 0x00);
        if (status) {
            buff[3] = 2;
        } else {
            buff[3] = 0;
        }
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_pitch_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_yaw_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x07, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    }
}

const viewlink_cmdLEN = {
    // cmd_DATA_len + 3(len+cmdid+cs)
    A1C1E1: 17,
    A1C1E1S1: 31,
    A2C2E2: 13,
    A2C2E2S2: 18,
    T1F1B1D1: 44,
    T2F2B2D2: 52,
    A1: 12,
    A2: 5,
    C1: 5,
    C2: 6,
    E1: 6,
    E2: 8,
    S1: 17,
    S2: 8,
    U: 5,
    V: 5,
    M_AHRS: 45,
    HEART_BEAT: 4,
    SHAKE_HAND: 4,
    ////FOLLOW CMD NOT +3
    T1F1B1D1_DATA: 41,
    T2F2B2D2_DATA: 49,
    T1_DATA: 22,
    F1_DATA: 1,
    B1_DATA: 6,
    D1_DATA: 12,
    T2_DATA: 18,
    F2_DATA: 15,
    B2_DATA: 11,
    D2_DATA: 5
}

const viewlink_cmdID = {
    A1C1E1: 0X30,
    A1C1E1S1: 0X32,
    A2C2E2: 0X31,
    A2C2E2S2: 0X33,
    A1: 0X1A,
    A2: 0X2A,
    C1: 0X1C,
    C2: 0X2C,
    E1: 0x1E,
    E2: 0X2E,
    S1: 0X16,
    S2: 0X26,
    U: 0X01,
    V: 0X02,
    M_AHRS: 0XB1,
    HEART_BEAT: 0X10,
    SHAKE_HAND: 0X00,
    T1F1B1D1: 0x40,
    T2F2B2D2: 0X41,
    FW: 0XFF
};

let viewlinkheadlen = 3;

const viewlinkFrameLen = {
    A1C1E1: viewlink_cmdLEN.A1C1E1 + viewlinkheadlen,
    A1C1E1S1: viewlink_cmdLEN.A1C1E1S1 + viewlinkheadlen,
    A2C2E2: viewlink_cmdLEN.A2C2E2 + viewlinkheadlen,
    A2C2E2S2: viewlink_cmdLEN.A2C2E2S2 + viewlinkheadlen,
    A1: viewlink_cmdLEN.A1 + viewlinkheadlen,
    A2: viewlink_cmdLEN.A2 + viewlinkheadlen,
    C1: viewlink_cmdLEN.C1 + viewlinkheadlen,
    C2: viewlink_cmdLEN.C2 + viewlinkheadlen,
    E1: viewlink_cmdLEN.E1 + viewlinkheadlen,
    E2: viewlink_cmdLEN.E2 + viewlinkheadlen,
    S1: viewlink_cmdLEN.S1 + viewlinkheadlen,
    S2: viewlink_cmdLEN.S2 + viewlinkheadlen,
    U: viewlink_cmdLEN.U + viewlinkheadlen,
    V: viewlink_cmdLEN.V + viewlinkheadlen,
    M_AHRS: viewlink_cmdLEN.M_AHRS + viewlinkheadlen,
    HEART_BEAT: viewlink_cmdLEN.HEART_BEAT + viewlinkheadlen,
    SHAKE_HAND: viewlink_cmdLEN.SHAKE_HAND + viewlinkheadlen
}

const viewlink_cs_pos = {
    A1C1E1: viewlinkFrameLen.A1C1E1 - 1,
    A1C1E1S1: viewlinkFrameLen.A1C1E1S1 - 1,
    A2C2E2: viewlinkFrameLen.A2C2E2 - 1,
    A2C2E2S2: viewlinkFrameLen.A2C2E2S2 - 1,
    A1: viewlinkFrameLen.A1 - 1,
    A2: viewlinkFrameLen.A2 - 1,
    C1: viewlinkFrameLen.C1 - 1,
    C2: viewlinkFrameLen.C2 - 1,
    E1: viewlinkFrameLen.E1 - 1,
    E2: viewlinkFrameLen.E2 - 1,
    S1: viewlinkFrameLen.S1 - 1,
    S2: viewlinkFrameLen.S2 - 1,
    U: viewlinkFrameLen.U - 1,
    V: viewlinkFrameLen.V - 1,
    M_AHRS: viewlinkFrameLen.M_AHRS - 1,
    HEART_BEAT: viewlinkFrameLen.HEART_BEAT - 1,
    SHAKE_HAND: viewlinkFrameLen.SHAKE_HAND - 1,
}

let mmcAddLen = 2;//(len+cs)
let mmcCmdLen = {// the position of checksum
    A1C1E1: viewlinkFrameLen.A1C1E1 + mmcAddLen,
    A1C1E1S1: viewlinkFrameLen.A1C1E1S1 + mmcAddLen,
    A2C2E2: viewlinkFrameLen.A2C2E2 + mmcAddLen,
    A2C2E2S2: viewlinkFrameLen.A2C2E2S2 + mmcAddLen,
    A1: viewlinkFrameLen.A1 + mmcAddLen,
    A2: viewlinkFrameLen.A2 + mmcAddLen,
    C1: viewlinkFrameLen.C1 + mmcAddLen,
    C2: viewlinkFrameLen.C2 + mmcAddLen,
    E1: viewlinkFrameLen.E1 + mmcAddLen,
    E2: viewlinkFrameLen.E2 + mmcAddLen,
    S1: viewlinkFrameLen.S1 + mmcAddLen,
    S2: viewlinkFrameLen.S2 + mmcAddLen,
    U: viewlinkFrameLen.U + mmcAddLen,
    V: viewlinkFrameLen.V + mmcAddLen,
    M_AHRS: viewlinkFrameLen.M_AHRS + mmcAddLen,
    HEART_BEAT: viewlinkFrameLen.HEART_BEAT + mmcAddLen,
    SHAKE_HAND: viewlinkFrameLen.SHAKE_HAND + mmcAddLen,
}
let C2_ctrl = {
    noaction: 0,
    eodzoomon: 6,
    eodzoomoff: 7,
    eoVEon: 0x10,
    eoVEoff: 0x11,
    ircolorbaron: 0x12,
    ircolorbaroff: 0x13,
    eoflipoff: 0x14,
    eoflipon: 0x15,
    defogon: 0x16,
    defogoff: 0x17,
    osdon: 0x18,
    osdoff: 0x19,
    irflipoff: 0x1a,
    irflipon: 0x1b,
    eoICRon: 0x4a,
    eoICRoff: 0x4b,
    eozoomto: 0x53,
    laserctrl: 0x74
}
let C2_laser_cmd = {
    noaction: 0,
    laseron: 0X0100,
    laseroff: 0X0200,
    laserzoomout: 0X0400,
    laserzoomin: 0X0500,
    synauto: 0X0600,
    manulaser: 0X0700

}
let C2_CMD = {
    ctrl: C2_ctrl.noaction,
    param: C2_laser_cmd.noaction
}

let mmcHeadLen = 2;
let mmcFrameLen = {// the position of checksum
    A1C1E1: mmcCmdLen.A1C1E1 + mmcHeadLen,
    A1C1E1S1: mmcCmdLen.A1C1E1S1 + mmcHeadLen,
    A2C2E2: mmcCmdLen.A2C2E2 + mmcHeadLen,
    A2C2E2S2: mmcCmdLen.A2C2E2S2 + mmcHeadLen,
    A1: mmcCmdLen.A1 + mmcHeadLen,
    A2: mmcCmdLen.A2 + mmcHeadLen,
    C1: mmcCmdLen.C1 + mmcHeadLen,
    C2: mmcCmdLen.C2 + mmcHeadLen,
    E1: mmcCmdLen.E1 + mmcHeadLen,
    E2: mmcCmdLen.E2 + mmcHeadLen,
    S1: mmcCmdLen.S1 + mmcHeadLen,
    S2: mmcCmdLen.S2 + mmcHeadLen,
    U: mmcCmdLen.U + mmcHeadLen,
    V: mmcCmdLen.V + mmcHeadLen,
    M_AHRS: mmcCmdLen.M_AHRS + mmcHeadLen,
    HEART_BEAT: mmcCmdLen.HEART_BEAT + mmcHeadLen,
    SHAKE_HAND: mmcCmdLen.SHAKE_HAND + mmcHeadLen,
}

let mmc_cs_pos = {// the position of checksum
    A1C1E1: mmcFrameLen.A1C1E1 - 1,
    A1C1E1S1: mmcFrameLen.A1C1E1S1 - 1,
    A2C2E2: mmcFrameLen.A2C2E2 - 1,
    A2C2E2S2: mmcFrameLen.A2C2E2S2 - 1,
    A1: mmcFrameLen.A1 - 1,
    A2: mmcFrameLen.A2 - 1,
    C1: mmcFrameLen.C1 - 1,
    C2: mmcFrameLen.C2 - 1,
    E1: mmcFrameLen.E1 - 1,
    E2: mmcFrameLen.E2 - 1,
    S1: mmcFrameLen.S1 - 1,
    S2: mmcFrameLen.S2 - 1,
    U: mmcFrameLen.U - 1,
    V: mmcFrameLen.V - 1,
    M_AHRS: mmcFrameLen.M_AHRS - 1,
    HEART_BEAT: mmcFrameLen.HEART_BEAT - 1,
    SHAKE_HAND: mmcFrameLen.SHAKE_HAND - 1,
}

let control_speed = 3;
var A1_PARAM_INT16 = {
    PARAM1: 0x0000,
    PARAM2: 0x0000,
    PARAM3: 0x0000,
    PARAM4: 0x0000
}
let A1_SERVO_STATUS = {
    motoroff: 0x00,
    manualcontrol: 0x01,
    followyaw: 0x03,
    homeposition: 0x04,
    trackmode: 0x06,
    turntorelativeangle: 0x09,
    lockyaw: 0x0a,
    turntoframeangle: 0x0b,
    rcmode: 0x0d,
    movetofinger: 0x0E,
    noaction: 0x0f
}

let A1_CMD = {
    server_status: A1_SERVO_STATUS.noaction,
    param1: A1_PARAM_INT16.PARAM1,
    param2: A1_PARAM_INT16.PARAM2,
    param3: A1_PARAM_INT16.PARAM3,
    param4: A1_PARAM_INT16.PARAM4
}

function pack_A1(A1CMD) {
    var A1_data_array = A1_to_array(A1CMD);//9
    var A1pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
    A1pack_array[3] = viewlink_cmdLEN.A1; //12
    A1pack_array[4] = viewlink_cmdID.A1;

    A1_data_array.forEach(function (item) { // 将A1CMD的数据提取出，拼接在A1pack_array
        A1pack_array.push(item)
    })

    A1pack_array[viewlink_cs_pos.A1] = viewlink_checksum(A1pack_array);
    return A1pack_array;
}

function A1_to_array(A1_CMD) {
    var array = new Array();
    array[0] = A1_CMD.server_status;

    array[1] = A1_CMD.param1 >> 8;
    array[2] = A1_CMD.param1 & 0xff;

    array[3] = A1_CMD.param2 >> 8;
    array[4] = A1_CMD.param2 & 0xff;

    array[5] = A1_CMD.param3 >> 8;
    array[6] = A1_CMD.param3 & 0xff;

    array[7] = A1_CMD.param4 >> 8;
    array[8] = A1_CMD.param4 & 0xff;

    return array;
}

function viewlink_checksum(array) {
    var checksum = array[3];
    var i = 4;
    var len = (array[3] & 0x3f) - 2;
    while (len--) {
        checksum = checksum ^ array[i];
        i++;
    }
    return checksum;
}

export const MMC_Gimbal_Z30Pro = {
    gimbal_mode_ctrl(mode) {
        var buff = new Array(0xA5, 0x08, 0x03, 0x00, 0x00);
        buff[3] = mode;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    zoom(zoom_ctrl) {
        var buff = new Array(0xA5, 0x04, 0x03, 0x00, 0x00);
        buff[3] = (zoom_ctrl >>> 0) & 0xff;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    change_pitch_angle(angle) {
        var buff = new Array(0xA5, 0x22, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    change_yaw_angle(angle) {
        var buff = new Array(0xA5, 0x23, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    camera_mode_ctrl(camera_mode) {
        var buff = new Array(0xA5, 0x09, 0x03, 0x00, 0x00);
        buff[3] = camera_mode;
        buff[4] = cal_crc_table(buff);
        return buff;
    },
    /**
     * 拍照
     * @param {*} param0 
     * @returns 
     */
    take_photo({ longitude = 0, latitude = 0, altitude = 0 }) {
        var buff = new Array(0xA5, 0x01, 16, /*index*/ 0x00, 0x00, /*altitude*/ 0x00, 0x00, 0x00, 0x00,
            /*latitude*/
            0x00, 0x00, 0x00, 0x00, /*longitude*/ 0x00, 0x00, 0x00, 0x00, 0x00);

        buff[5] = (altitude) & 0xff;
        buff[6] = (altitude >> 8) & 0xff;
        buff[7] = (altitude >> 16) & 0xff;
        buff[8] = (altitude >> 24) & 0xff;

        buff[9] = (latitude) & 0xff;
        buff[10] = (latitude >> 8) & 0xff;
        buff[11] = (latitude >> 16) & 0xff;
        buff[12] = (latitude >> 24) & 0xff;

        buff[13] = (longitude) & 0xff;
        buff[14] = (longitude >> 8) & 0xff;
        buff[15] = (longitude >> 16) & 0xff;
        buff[16] = (longitude >> 24) & 0xff;

        buff[17] = cal_crc_table(buff);
        return buff
    },
    /**
     * 
     * @param {*} status: 1 开始录像, 0 停止录像
     */
    record(status) {
        var buff = new Array(0xA5, 0x05, 0x03, 0x00, 0x00);
        if (status) {
            buff[3] = 2;
        } else {
            buff[3] = 0;
        }
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_pitch_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_yaw_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x07, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    },

    viewlink_checksum(array) {
        var checksum = array[3];
        var i = 4;
        var len = (array[3] & 0x3f) - 2;
        while (len--) {
            checksum = checksum ^ array[i];
            i++;
        }
        return checksum;
    },
    pack_FW(data, len) {
        var viewlinkheadlen = 3;
        var FW_data_array = data;//9
        var FWpack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
        var viewlink_cmdLEN_FW = len + 3;
        var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
        var viewlink_cs_pos_FW = viewlinkFrameLen_FW - 1;
        FWpack_array[3] = viewlink_cmdLEN_FW;
        FWpack_array[4] = viewlink_cmdID.FW;
        FW_data_array.forEach(function (item) {
            FWpack_array.push(item)
        })
        FWpack_array[viewlink_cs_pos_FW] = MMC_Gimbal_Z30Pro.viewlink_checksum(FWpack_array);
        return FWpack_array;
    },
    C2_to_array(C2_CMD) {
        var array = new Array();
        array[0] = C2_CMD.ctrl;
        array[1] = C2_CMD.param >> 8;
        array[2] = C2_CMD.param & 0XFF;
        return array;
    },
    pack_C2(C2_CMD) {
        var C2_data_array = MMC_Gimbal_Z30Pro.C2_to_array(C2_CMD);//9
        var C2pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
        C2pack_array[3] = viewlink_cmdLEN.C2;
        C2pack_array[4] = viewlink_cmdID.C2;
        C2_data_array.forEach(function (item) {
            C2pack_array.push(item)
        })
        C2pack_array[viewlink_cs_pos.C2] = MMC_Gimbal_Z30Pro.viewlink_checksum(C2pack_array);
        return C2pack_array;
    },
    //数据分包发送函数
    package_send(buff, len) {
        var i = 0;
        var j = 0;
        var count = 0;
        // var alen = len;
        var hexbuf = [];
        var sendbuf = [];

        if (len % 48 == 0) {
            count = parseInt(len / 48, 10);
        } else {
            count = parseInt(len / 48, 10) + 1;
        }

        for (i = 0; i < count; i++) {
            if ((count - i) == 1) {
                for (j = 0; j < len - i * 48; j++) {
                    sendbuf[j] = buff[i * 48 + j];
                    hexbuf[j] = Number(buff[i * 48 + j]).toString(16);
                }
                // webSocket.send(sendbuf);
            } else {
                for (j = 0; j < 48; j++) {
                    sendbuf[j] = buff[i * 48 + j];
                    hexbuf[j] = Number(buff[i * 48 + j]).toString(16);
                }
                // webSocket.send(sendbuf);
            }
        }
        return sendbuf;
    },

    VE_mode_ctrl(VEmode, FW_data_len) {
        var FW_data_len = 0;
        var viewlinkheadlen = 3;
        var mmcAddLen = 2;
        var mmcHeadLen = 2;

        var viewlink_cmdLEN_FW = FW_data_len + 3;
        var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
        var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
        var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        var buff = new Array(0xA5, 0x4f, 0x00);
        FW_data_len = 6;
        var FW_CMD_data = [0x81, 0x01, 0x04, 0x3D, 0x06, 0xff];
        if (VEmode == 0) {
            FW_CMD_data[4] = 3;
        }

        mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
        mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        buff[2] = mmcCmdLen_FW;

        var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
        buff_arr.forEach(function (item) {
            buff.push(item)
        })
        buff[mmc_cs_pos_FW] = cal_crc_table(buff);

        let result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);
        return result;
    },
    NR_mode_ctrl(NRmode) {
        var FW_data_len = 0;
        var viewlinkheadlen = 3;
        var mmcAddLen = 2;
        var mmcHeadLen = 2;

        var viewlink_cmdLEN_FW = FW_data_len + 3;
        var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
        var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
        var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        var buff = new Array(0xA5, 0x4f, 0x00);
        FW_data_len = 6;
        var FW_CMD_data = [0x81, 0x01, 0x04, 0x53, 0x05, 0xff];
        if (NRmode == 0) {
            FW_CMD_data[4] = 0;
        }

        mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
        mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        buff[2] = mmcCmdLen_FW;

        var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
        buff_arr.forEach(function (item) {
            buff.push(item)
        })
        buff[mmc_cs_pos_FW] = cal_crc_table(buff);
        let result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);
        return result;
    },
    camera_WD_ctrl(cameraWD) {
        var FW_data_len = 0;
        var viewlinkheadlen = 3;
        var mmcAddLen = 2;
        var mmcHeadLen = 2;

        var viewlink_cmdLEN_FW = FW_data_len + 3;
        var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
        var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
        var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        var buff = new Array(0xA5, 0x4f, 0x00);
        FW_data_len = 6;
        var FW_CMD_data = [0x81, 0x01, 0x04, 0x3d, 0x02, 0xff];
        if (cameraWD == 1)
            FW_CMD_data[4] = 2;
        else
            FW_CMD_data[4] = 3;

        mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
        mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        buff[2] = mmcCmdLen_FW;

        var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
        buff_arr.forEach(function (item) {
            buff.push(item)
        })
        buff[mmc_cs_pos_FW] = cal_crc_table(buff);
        let result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);
        return result;
    },
    DZOOM_mode_ctrl(DZOOMmode) {

        var FW_data_len = 0;
        var viewlinkheadlen = 3;
        var mmcAddLen = 2;
        var mmcHeadLen = 2;

        var viewlink_cmdLEN_FW = FW_data_len + 3;
        var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
        var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
        var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        var buff = new Array(0xA5, 0x4f, 0x00);
        FW_data_len = 6;
        var FW_CMD_data = [0x81, 0x01, 0x04, 0x06, 0x02, 0xff];
        if (DZOOMmode == 0) {
            FW_CMD_data[4] = 3;
        }

        mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
        mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        buff[2] = mmcCmdLen_FW;

        var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
        buff_arr.forEach(function (item) {
            buff.push(item)
        })
        buff[mmc_cs_pos_FW] = cal_crc_table(buff);
        let result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);

        return result;
    },
    stabilizer_mode_ctrl(stabilizer_mode) {

        let result = []

        var FW_data_len = 0;
        var viewlinkheadlen = 3;
        var mmcAddLen = 2;
        var mmcHeadLen = 2;

        var viewlink_cmdLEN_FW = FW_data_len + 3;
        var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
        var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
        var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        var buff = new Array(0xA5, 0x4f, 0x00);
        FW_data_len = 6;
        var FW_CMD_data = [0x81, 0x01, 0x04, 0x34, 0x02, 0xff];
        if (stabilizer_mode == 0) {
            FW_CMD_data[4] = 3;
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);
        } else if (stabilizer_mode == 1) {
            FW_CMD_data[4] = 2;
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result.push(MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW))

            var buff1 = new Array(0xA5, 0x4f, 0x00);
            FW_data_len = 7;
            var FW2_CMD_data = [0x81, 0x01, 0x7e, 0x04, 0x34, 0x02, 0xff];
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff1[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW2_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff1.push(item)
            })
            buff1[mmc_cs_pos_FW] = cal_crc_table(buff1);
            result.push(MMC_Gimbal_Z30Pro.package_send(buff1, mmcFrameLen_FW))
        } else if (stabilizer_mode == 2) {
            FW_data_len = 7;
            var FW3_CMD_data = [0x81, 0x01, 0x7e, 0x04, 0x34, 0x03, 0xff];
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW3_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);
        }
        return result;
    },
    videooutput_mode_ctrl(videooutput_mode) {

        let result = []

        var FW_data_len = 0;
        var viewlinkheadlen = 3;
        var mmcAddLen = 2;
        var mmcHeadLen = 2;

        var viewlink_cmdLEN_FW = FW_data_len + 3;
        var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
        var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
        var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        var buff = new Array(0xA5, 0x4f, 0x00);
        FW_data_len = 8;
        var FW_CMD_data = [0x81, 0x01, 0x04, 0x24, 0x72, 0x02, 0x08, 0xff]; //81 01 04 24 72 00 04 ff 
        if (videooutput_mode == 0) {
            FW_CMD_data[5] = 1;
            FW_CMD_data[6] = 5;
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result.push(MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW))
        } else if (videooutput_mode == 1) {
            FW_CMD_data[5] = 0;
            FW_CMD_data[6] = 7;
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result.push(MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW))
        } else if (videooutput_mode == 2) {
            FW_CMD_data[5] = 2;
            FW_CMD_data[6] = 8;
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result.push(MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW))
        }
        //8x 01 04 3F 01 7F FF
        var buff1 = new Array(0xA5, 0x4f, 0x00);
        var FW1_CMD_data = [0x81, 0x01, 0x04, 0x3f, 0x01, 0x7f, 0xff];
        FW_data_len = 7;
        mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
        mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        buff1[2] = mmcCmdLen_FW;

        var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW1_CMD_data, FW_data_len);
        buff_arr.forEach(function (item) {
            buff1.push(item)
        })
        buff1[mmc_cs_pos_FW] = cal_crc_table(buff1);
        result.push(MMC_Gimbal_Z30Pro.package_send(buff1, mmcFrameLen_FW))
        //8x 01 04 19 03 FF Camera reset
        var buff2 = new Array(0xA5, 0x4f, 0x00);
        var FW2_CMD_data = [0x81, 0x01, 0x04, 0x19, 0x03, 0xff];
        FW_data_len = 6;
        mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
        mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        buff2[2] = mmcCmdLen_FW;

        var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW2_CMD_data, FW_data_len);
        buff_arr.forEach(function (item) {
            buff2.push(item)
        })
        buff2[mmc_cs_pos_FW] = cal_crc_table(buff2);
        result.push(MMC_Gimbal_Z30Pro.package_send(buff2, mmcFrameLen_FW))

        return result;
    },
    ICR_mode_ctrl(icrmode) {

        let result = []

        var FW_data_len = 0;
        var viewlinkheadlen = 3;
        var mmcAddLen = 2;
        var mmcHeadLen = 2;

        var viewlink_cmdLEN_FW = FW_data_len + 3;
        var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
        var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
        var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        var buff = new Array(0xA5, 0x4f, 0x00);
        buff[2] = mmcCmdLen.C2;
        if (icrmode == 2) {
            FW_data_len = 6;
            var FW_CMD_data = [0x81, 0x01, 0x04, 0x51, 0x04, 0xff]; //8x 01 04 51 04 FF
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);
        } else if (icrmode == 1) {
            FW_data_len = 6;
            var FW_CMD_data = [0x81, 0x01, 0x04, 0x51, 0x02, 0xff]; //8x 01 04 51 03 FF
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result.push(MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW))

            C2_CMD.ctrl = C2_ctrl.eoICRon;
            var buff_arr = MMC_Gimbal_Z30Pro.pack_C2(C2_CMD);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos.C2] = cal_crc_table(buff);
            result.push(MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen.C2))

        } else if (icrmode == 0) {
            FW_data_len = 6;
            var FW_CMD_data = [0x81, 0x01, 0x04, 0x51, 0x03, 0xff]; //8x 01 04 51 03 FF
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result.push(MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW))
            C2_CMD.ctrl = C2_ctrl.eoICRoff;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_C2(C2_CMD);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos.C2] = cal_crc_table(buff);
            result.push(MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen.C2))
        }
        return result;
    },

    WB_mode_ctrl(WB_mode) {
        let result = []

        var FW_data_len = 0;
        var viewlinkheadlen = 3;
        var mmcAddLen = 2;
        var mmcHeadLen = 2;

        var viewlink_cmdLEN_FW = FW_data_len + 3;
        var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
        var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
        var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
        var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

        var buff = new Array(0xA5, 0x4f, 0x00);
        FW_data_len = 6;
        var FW_CMD_data = [0x81, 0x01, 0x04, 0x35, 0x00, 0xff]; //8x 01 04 35 00 FF
        if (WB_mode == 0) {
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);
        } else if (WB_mode == 1) {
            FW_CMD_data[4] = 1;
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);
        } else if (WB_mode == 2) {
            FW_CMD_data[4] = 2;
            mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
            mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
            mmc_cs_pos_FW = mmcFrameLen_FW - 1;

            buff[2] = mmcCmdLen_FW;

            var buff_arr = MMC_Gimbal_Z30Pro.pack_FW(FW_CMD_data, FW_data_len);
            buff_arr.forEach(function (item) {
                buff.push(item)
            })
            buff[mmc_cs_pos_FW] = cal_crc_table(buff);
            result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen_FW);
        }

        return result;
    },


    gimbal_yaw_pitch_ctrl(yawctrl, pitchctrl) {// speed 1 -1  0
        // console.log(yawctrl,pitchctrl);
        var yawrealspeed = ((yawctrl * control_speed) >>> 0) & 0xff; // 转换为正，-3 转换为无符号253  -1 转为 255
        var pitchrealspeed = ((pitchctrl * control_speed) >>> 0) & 0xff; // 转换为正，-3 转换为无符号253  -1 转为 255
        var pitchspeed = 0;
        var yawspeed = 0

        console.log('@@@', yawrealspeed, pitchrealspeed);

        var buff = new Array(0xA5, 0x4f, 0x00);
        buff[2] = mmcCmdLen.A1;
        A1_CMD.server_status = A1_SERVO_STATUS.manualcontrol;

        if (yawrealspeed < 100) {
            yawspeed = yawrealspeed; // 
            A1_CMD.param1 = yawspeed * 100; //viewlink 
        }
        else {
            yawspeed = yawrealspeed - 256;
            A1_CMD.param1 = yawspeed * 100 + 65536;
        }

        if (pitchrealspeed < 100) {
            pitchspeed = pitchrealspeed; // 向上
            A1_CMD.param2 = pitchspeed * 100; //viewlink 
        }
        else {
            pitchspeed = pitchrealspeed - 256;
            A1_CMD.param2 = pitchspeed * 100 + 65536;
        }

        var buff_arr = pack_A1(A1_CMD);
        buff_arr.forEach(function (item) {
            buff.push(item)
        })
        buff[mmc_cs_pos.A1] = cal_crc_table(buff);


        console.log('send', buff, mmcFrameLen.A1);
        // package_send(buff,mmcFrameLen.A1);
        let result = MMC_Gimbal_Z30Pro.package_send(buff, mmcFrameLen.A1);


        // if (pitchctrl == 0) {
        //     pitch_move = false;
        // } else {
        //     pitch_move = true;
        // }

        // if (yawctrl == 0) {
        //     yaw_move = false;
        // } else {
        //     yaw_move = true;
        // }

        return result;
    }


}

export const MMC_Gimbal_P0_Pro = {
    /**
     * 
     * @param {*} value: 2 播放，0 暂停
     * @returns 
     */
    gimbal_audio_play_ctl(value) {
        var buff = new Array(0xa5, 0x22, 0x03);
        buff[3] = value;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    /**
     * 下一首
     * @returns 
     */
    gimbal_audio_play_next_ctl() {
        var buff = new Array(0xa5, 0x23, 0x03, 0x01);
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_pitch_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff;
    }
}

export const MMC_Gimbal_L50 = {
    /**
     * 常亮模式
     * @param {*} bright 
     * @returns 
     */
    constant_bright_ctrl(bright) {
        var buff = new Array(0xa5, 0x01, 0x03);
        if (bright) {
            buff[3] = 2;
        }
        else {
            buff[3] = 1;
        }
        buff[4] = cal_crc_table(buff);
        return buff
    },
    /**
     * 闪光模式
     * @param {*} flicker_status 
     * @returns 
     */
    flicker_ctrl(flicker_status = false) {
        var buff = new Array(0xa5, 0x01, 0x03);
        if (flicker_status) {
            buff[3] = 1;
        } else {
            buff[3] = 3;
        }
        buff[4] = cal_crc_table(buff);
        return buff
    },
    /**
     * 回中模式
     * @param {*} middle_status 
     * @returns 
     */
    middle_ctrl(middle_status = false) {
        var buff = new Array(0xa5, 0x08, 0x03);
        if (middle_status) {
            buff[3] = 1;
        } else {
            buff[3] = 2;
        }
        buff[4] = cal_crc_table(buff);
        return buff
    },
    /**
     * 极亮模式
     * @param {*} very_bright_status 
     */
    very_bright_ctrl(very_bright_status = false) {
        var buff = new Array(0xa5, 0x04, 0x04, 0x00);
        if (very_bright_status) {
            buff[4] = 1;
        } else {
            buff[4] = 2;
        }
        buff[5] = cal_crc_table(buff);
        return buff
    },
    gimbal_pitch_ctrl(speed) {
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        if (speed == 1) {
            buff[3] = 0x03;
        }
        if (speed == -1) {
            buff[3] = 0xFD;
        }
        buff[4] = cal_crc_table(buff);
        return buff
    }
}

export const MMC_Gimbal_ZT1 = {
    gimbal_mode_ctrl(mode) {
        var buff = new Array(0xA5, 0xA4, 0x03, 0x00, 0x00);
        buff[3] = mode;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    change_pitch_angle(angle) {
        var buff = new Array(0xA5, 0xA2, 0x03);
        if (angle > 30 || angle < -90) {
            return;
        }
        buff[3] = angle & 0xff;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    change_yaw_angle(angle) {
        var buff = new Array(0xA5, 0xA3, 0x04, 0x00, 0x00, 0x00);
        buff[3] = angle & 0xff;
        buff[4] = (angle >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    gimbal_pitch_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0xA0, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_yaw_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0xA1, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    }
}

export const MMC_Gimbal_S1 = {
    lntelligent_mode_ctrl(lntelligent_switch_status) {
        var buff = new Array(0xa5, 0x02, 0x03)
        if (lntelligent_switch_status) {
            buff[3] = 1
        } else {
            buff[3] = 0
        }

        buff[4] = cal_crc_table(buff)
        return buff
    },
    motor_weight() {
        var buff = new Array(0xa5, 0x01, 0x03, 0x01)
        buff[4] = cal_crc_table(buff)
        return buff
    },
    gimbal_pitch_ctrl(speed) {
        var buff = new Array(0xa5, 0x05, 0x03, 0x00, 0x00)
        if (speed == 1) {
            buff[3] = 0x03
        }
        if (speed == -1) {
            buff[3] = 0xfd
        }
        buff[4] = cal_crc_table(buff)
        return buff
    }
}

export const MMC_Gimbal_FF6 = {
    fire_ctrl(gimbal_mode) {
        var buff = new Array(0xa5, 0x01, 0x03, 0x01, 0x00);
        buff[3] = gimbal_mode;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_pitch_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_yaw_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x07, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    }
}

export const MMC_Gimbal_ZT30N = {
    gimbal_mode_ctrl(mode) {
        var buff = new Array(0xA5, 0x08, 0x03, 0x00, 0x00);
        buff[3] = mode;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_image_mode(mode) {
        var buff = new Array(0xa5, 0x25, 0x03, 0x00, 0x00);
        buff[3] = parseInt(mode);
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_thermal_mode(mode) {
        var buff = new Array(0xa5, 0x27, 0x03, 0x00, 0x00);
        buff[3] = mode;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_thermal_zoom(zoom) {
        var buff = new Array(0xa5, 0x29, 0x03, 0x00, 0x00);
        buff[3] = zoom;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    zoom(zoom_ctrl) {
        var buff = new Array(0xA5, 0x04, 0x03, 0x00, 0x00);
        buff[3] = (zoom_ctrl >>> 0) & 0xff;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_pitch_ctrl(speed, control_speed = 1) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_yaw_ctrl(speed, control_speed = 1) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x07, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    }
}

export const MMC_Gimbal_S79 = {
    fire_ctrl() {
        var buff = new Array(0xa5, 0x20, 0x03, 0x01, 0x00)
        buff[3] = 1;
        buff[4] = cal_crc_table(buff)
        return buff
    }
}

export const MMC_Gimbal_FE8 = {
    laser_shine_ctrl() {
        var buff = new Array(0xa5, 0x20, 0x03, 0x01, 0x00)
        buff[3] = 1;
        buff[4] = cal_crc_table(buff)
        return buff
    },
    gimbal_pitch_ctrl(speed) {
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        if (speed == 1) {
            buff[3] = 0x03;
        }
        if (speed == -1) {
            buff[3] = 0xFD;
        }
        buff[4] = cal_crc_table(buff);
        return buff
    }
}

export const MMC_Gimbal_Z33N = {
    gimbal_mode_ctrl(mode) {
        var buff = new Array(0xA5, 0x08, 0x03, 0x00, 0x00);
        buff[3] = mode;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    zoom(zoom_ctrl) {
        var buff = new Array(0xA5, 0x04, 0x03, 0x00, 0x00);
        buff[3] = (zoom_ctrl >>> 0) & 0xff;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    change_pitch_angle(angle) {
        var buff = new Array(0xA5, 0x22, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    change_yaw_angle(angle) {
        var buff = new Array(0xA5, 0x23, 0x04, 0x00, 0x00, 0x00);
        var uint16data = g_convert_native_float_to_float16(angle);
        buff[3] = (uint16data) & 0xff;
        buff[4] = (uint16data >> 8) & 0xff;
        buff[5] = cal_crc_table(buff);
        return buff
    },
    camera_mode_ctrl(camera_mode) {
        var buff = new Array(0xA5, 0x09, 0x03, 0x00, 0x00);
        buff[3] = camera_mode;
        buff[4] = cal_crc_table(buff);
        return buff;
    },
    /**
     * 拍照
     * @param {*} param0 
     * @returns 
     */
    take_photo({ longitude = 0, latitude = 0, altitude = 0 }) {
        var buff = new Array(0xA5, 0x01, 16, /*index*/ 0x00, 0x00, /*altitude*/ 0x00, 0x00, 0x00, 0x00,
            /*latitude*/
            0x00, 0x00, 0x00, 0x00, /*longitude*/ 0x00, 0x00, 0x00, 0x00, 0x00);

        buff[5] = (altitude) & 0xff;
        buff[6] = (altitude >> 8) & 0xff;
        buff[7] = (altitude >> 16) & 0xff;
        buff[8] = (altitude >> 24) & 0xff;

        buff[9] = (latitude) & 0xff;
        buff[10] = (latitude >> 8) & 0xff;
        buff[11] = (latitude >> 16) & 0xff;
        buff[12] = (latitude >> 24) & 0xff;

        buff[13] = (longitude) & 0xff;
        buff[14] = (longitude >> 8) & 0xff;
        buff[15] = (longitude >> 16) & 0xff;
        buff[16] = (longitude >> 24) & 0xff;

        buff[17] = cal_crc_table(buff);
        return buff
    },
    /**
     * 
     * @param {*} status: 1 开始录像, 0 停止录像
     */
    record(status) {
        var buff = new Array(0xA5, 0x05, 0x03, 0x00, 0x00);
        if (status) {
            buff[3] = 2;
        } else {
            buff[3] = 0;
        }
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_pitch_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x06, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    },
    gimbal_yaw_ctrl(speed, control_speed) {
        var realspeed = ((speed * control_speed) >>> 0) & 0xff;
        var buff = new Array(0xA5, 0x07, 0x03, 0x00, 0x00);
        buff[3] = realspeed;
        buff[4] = cal_crc_table(buff);
        return buff
    }
}